import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// @ts-ignore
import { Markup } from "react-render-markup";
import { Box, CircularProgress, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  ProductPageFragment,
  ProductPageImageFragment,
  useAddItemToBasketMutation,
  useCreateBasketMutation,
  useGetBasketLazyQuery,
  useGetProductLazyQuery,
} from "../../generated/graphql";
import { Section, SectionItem } from "../../styles/Layout";
import { toPrice } from "../../helpers/money";
import { fonts } from "../../theme";

type Props = {
  setBasketDrawerOpen: (open: boolean) => void;
};

const ProductPage = ({ setBasketDrawerOpen }: Props) => {
  const { id } = useParams<{ id: string }>();
  const [getProduct, { data, loading }] = useGetProductLazyQuery();
  const [getBasket] = useGetBasketLazyQuery();
  const [createBasket, { loading: creatingBasket }] = useCreateBasketMutation();
  const [addItemToBasket, { loading: addingToBasket }] = useAddItemToBasketMutation();
  const [selectedImage, setSelectedImage] = useState<ProductPageImageFragment>();
  const [selectedQuantity, setSelectedQuantity] = useState("1");
  const dropdownOptions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const product = data?.product;
  const [errorMessage, setErrorMessage] = useState("");
  const outOfStock = !product?.totalInventory || product.totalInventory < 1;
  const [selectedVariantId, setSelectedVariantId] = useState<string | undefined>();

  const getPrice = (product: ProductPageFragment) => {
    if (product.priceRange.minVariantPrice.amount === product.priceRange.maxVariantPrice.amount) {
      return toPrice(product.priceRange.minVariantPrice.amount);
    } else {
      return `${toPrice(product.priceRange.minVariantPrice.amount)} - ${toPrice(product.priceRange.maxVariantPrice.amount)}`;
    }
  };

  const addToBasket = async () => {
    try {
      setErrorMessage("");
      const selectedVariant = product?.variants.nodes.find((variant) => variant.id === selectedVariantId);
      const basketId = localStorage.getItem("basketId");
      const basket = basketId ? await getBasket({ variables: { basketId } }) : null;
      if (!selectedVariant) {
        return setErrorMessage("Something went wrong, please refresh and try again");
      }
      if (basket?.data?.cart?.id) {
        await addItemToBasket({
          variables: { basketId: basket.data.cart.id, merchandiseId: selectedVariant.id, quantity: Number(selectedQuantity) },
        });
      } else {
        const basket = await createBasket({
          variables: { merchandiseId: selectedVariant.id, quantity: Number(selectedQuantity) },
        });
        localStorage.setItem("basketId", basket.data?.cartCreate?.cart?.id || "");
      }
      setBasketDrawerOpen(true);
    } catch (error) {
      setErrorMessage("Something went wrong, please try again");
    }
  };

  useEffect(() => {
    if (id) {
      getProduct({ variables: { id: `gid://shopify/Product/${id}` } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (product) {
      setSelectedImage(product.images.nodes[0]);
      setSelectedVariantId(product.variants.nodes[0].id);
    }
  }, [product]);

  return loading ? (
    <Box height="50vh" display="flex" justifyContent="center" alignItems="center">
      <CircularProgress color="primary" />
    </Box>
  ) : product ? (
    <Section flexDirection="row">
      <SectionItem flexBasis={{ xs: "100%", md: "50%" }} alignItems="start" direction="row" border>
        <Stack flexGrow={1} height="100%">
          <img
            src={(selectedImage || product.images.nodes[0]).src}
            alt={(selectedImage || product.images.nodes[0]).altText || "enlarged thumbnail"}
          />
        </Stack>
        {product.images.nodes.length > 1 && (
          <Stack gap={1} padding={1}>
            {product?.images.nodes.map((image) => (
              <Box key={image.id} height={100} width={100}>
                <img src={image.src} alt={image.altText || "thumbnail"} onClick={() => setSelectedImage(image)} />
              </Box>
            ))}
          </Stack>
        )}
      </SectionItem>
      <SectionItem flexBasis={{ xs: "100%", md: "50%" }} alignItems="start" padding={{ xs: 4, md: 4 }} gap={4}>
        <Stack gap={2}>
          <div>
            <Typography variant="h4" fontFamily={fonts.regularBold}>
              {product.title}
            </Typography>
            <Typography variant="h5" color="text.secondary" fontFamily={fonts.regularBold}>
              {getPrice(product)}
            </Typography>
            <Typography variant="subtitle1">
              {outOfStock ? "Out of stock" : `(${product.totalInventory} left in stock)`}
            </Typography>
          </div>
          {product.descriptionHtml && (
            <div>
              <Markup markup={product.descriptionHtml} style={{ margin: 0 }} />
            </div>
          )}
        </Stack>

        <Stack gap={2} width={{ xs: "100%", md: "auto" }}>
          {product.variants.nodes.length > 1 && (
            <Select
              size="small"
              key={selectedVariantId}
              value={selectedVariantId || product.variants.nodes[0].id}
              onChange={({ target }) => setSelectedVariantId(target.value)}
            >
              {product.variants.nodes.map((variant) => (
                <MenuItem key={variant.id} value={variant.id}>
                  {variant.title}
                </MenuItem>
              ))}
            </Select>
          )}

          <Stack gap={2} direction="row" width={{ xs: "100%", md: "auto" }}>
            <Select
              id="quantity-select"
              size="small"
              value={selectedQuantity.toString()}
              onChange={(event: SelectChangeEvent) => setSelectedQuantity(event.target.value)}
            >
              {dropdownOptions.slice(0, product.totalInventory || 10).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>

            <LoadingButton
              variant="contained"
              size="large"
              fullWidth
              onClick={addToBasket}
              loading={creatingBasket || addingToBasket}
              disabled={outOfStock}
            >
              Add to basket
            </LoadingButton>
          </Stack>
        </Stack>

        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </SectionItem>
    </Section>
  ) : (
    <Typography variant="h3">Product not found</Typography>
  );
};

export default ProductPage;
