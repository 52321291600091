import { Link } from "react-router-dom";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useGetProductsQuery } from "../../generated/graphql";
import { Section } from "../../styles/Layout";
import { toPrice } from "../../helpers/money";
import { colors, fonts } from "../../theme";

const Shop = () => {
  const { data, loading } = useGetProductsQuery();

  const getPrice = (product: any) => {
    if (product.priceRange.minVariantPrice.amount === product.priceRange.maxVariantPrice.amount) {
      return toPrice(product.priceRange.minVariantPrice.amount);
    } else {
      return `${toPrice(product.priceRange.minVariantPrice.amount)} - ${toPrice(product.priceRange.maxVariantPrice.amount)}`;
    }
  };

  return (
    <>
      {loading ? (
        <Box height="50vh" display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Section padding={4} justifyContent="center">
            <Typography variant="h3" align="center">
              Organic Farm Shop
            </Typography>
          </Section>
          <Section padding={{ xs: 2, md: 2 }}>
            <Grid container spacing={2}>
              {data?.products.nodes.length ? (
                data.products.nodes.map((product) => (
                  <Grid item xs={6} sm={4} key={product.id} margin={0}>
                    <Link to={`/products/${product.id.split("Product/")[1]}`}>
                      <Box height={{ xs: "180px", md: "300px" }} width="100%" border={`2px solid ${colors.dark}`}>
                        <img src={product.images.nodes[0].src} alt={product.images.nodes[0].altText || ""} />
                      </Box>
                      <Stack gap={0.5} paddingTop={1} width="100%">
                        <Typography variant="subtitle1" fontFamily={fonts.regularBold}>
                          {product.title}
                        </Typography>
                        <Typography variant="subtitle1" fontFamily={fonts.regularBold} color="text.secondary">
                          {getPrice(product)} {!product.totalInventory || product.totalInventory < 1 ? "(Out of stock)" : ""}
                        </Typography>
                      </Stack>
                    </Link>
                  </Grid>
                ))
              ) : (
                <Typography variant="h3" fontFamily={fonts.regularBold} align="center">
                  No products available at this time
                </Typography>
              )}
            </Grid>
          </Section>
        </>
      )}
    </>
  );
};

export default Shop;
