import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../theme";

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${colors.dark};

  @media screen and (max-width: 895px) {
    height: 11vh;
  }
`;

export const MobileNavItem = styled.button<{ position: "left" | "right" }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background: none;
  border: 0;
  outline: none;
  ${({ position }) => `${position}: 10px;`}

  img {
    width: 50%;
    height: auto;
  }
`;

export const LuckingtonLogoMobile = styled.div`
  width: 45%;
  height: 100%;
  flex-grow: 0;
  margin: auto;
`;

export const NavList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  flex-grow: 1;
`;

export const NavItem = styled(NavLink)`
  flex: 1 1 0;
  height: 100%;
  text-decoration: none;
  color: inherit;
  border-right: 2px solid ${colors.dark};

  &.active > div {
    border-color: ${colors.dark};
  }

  @media screen and (max-width: 895px) {
    height: auto;
    flex: 0;
    border: 0;
    border-right: 0;
  }
`;

export const BasketNavItem = styled.div`
  flex: 1 1 0;
  height: 100%;
  color: inherit;
  cursor: pointer;

  @media screen and (max-width: 895px) {
    height: auto;
    flex: 0;
  }
`;

export const NavItemContainer = styled.div`
  border: 2px solid transparent;
  margin: 5px;
  text-align: center;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: ${colors.dark};
  }

  @media screen and (max-width: 895px) {
    height: 55px;
    border: 0;
  }

  p {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1.65px;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 895px) {
      font-size: 18px;
    }
  }
`;

export const StyledLogo = styled.img`
  display: block;
  padding: 13px 15px;
  object-fit: contain;
`;
