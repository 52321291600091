import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Stack, Typography } from "@mui/material";
import cows9 from "../assets/cows_9.jpg";
import cows7 from "../assets/cows_7.jpg";
import cows2 from "../assets/cows_2.jpg";
import grassSvg from "../assets/grass.svg";
import cowSvg from "../assets/cow.svg";
import mobGrazingSvg from "../assets/mob-grazing.svg";
import soilAssociationLogo from "../assets/soilAssociation.png";
import { FirstSection, Section, SectionItem } from "../styles/Layout";
import { colors } from "../theme";

const FactsImage = styled.img`
  max-width: 60%;
  max-height: 200px;
  flex-grow: 1;
  object-fit: contain;

  @media screen and (max-width: 895px) {
    max-width: 100%;
    max-height: 100px;
  }
`;

const FindOutMoreContent = styled.div`
  border: 2px solid transparent;
  margin: 8px;
  text-align: center;

  &:hover {
    border-color: ${colors.dark};
  }
`;

const Home = () => {
  return (
    <div>
      <FirstSection position="relative">
        <img className="percent" src={cows9} alt="luckington cows" />
        <Stack
          position="absolute"
          top="0"
          left="0"
          width="100%"
          paddingY={{ xs: 4, md: 8 }}
          paddingX={{ xs: 2, md: 8 }}
          zIndex={2}
          justifyContent="center"
          gap={2}
        >
          <Typography variant="h1" align="center" color="white" letterSpacing={2}>
            Organically Mob Grazed
          </Typography>
          <Link to="/shop" style={{ textAlign: "center" }}>
            <Button variant="contained" size="large" color="secondary">
              Farm shop
            </Button>
          </Link>
        </Stack>
      </FirstSection>
      <Section direction="row">
        <SectionItem padding={{ xs: 4, md: 8 }} border>
          <Typography variant="h3">
            Our native breed cattle are born locally and reared on a completely natural pasture diet. We move the herd every day,
            allowing a diversity of plants and grasses to grow organically. This helps regenerate the soil and takes carbon from
            the atmosphere.
          </Typography>
        </SectionItem>
        <SectionItem>
          <img src={cows7} alt="cows" />
        </SectionItem>
      </Section>
      <Section direction="row" justifyContent="center">
        <SectionItem padding={{ xs: 4, md: 8 }} maxWidth={{ xs: "100%", md: "60%" }}>
          <Typography variant="h3" align="center">
            Our mission is to produce natural food whilst improving the environment.
          </Typography>
        </SectionItem>
      </Section>
      <Section direction="row">
        <SectionItem>
          <img src={cows2} alt="cow" />
        </SectionItem>
      </Section>

      <Section direction="row">
        <SectionItem direction={{ xs: "column", md: "row" }} padding={{ xs: 4, md: 8 }} gap={3}>
          <Stack flex={1}>
            <Typography variant="h3" align="center" marginBottom={2}>
              Our Story
            </Typography>
            <Typography>
              Much of modern farming is a capitalist business. However, measuring profits as revenues minus tangible costs ignores
              the longer term intangible costs. In many cases these costs are borne by all of us, not just the farmer.
            </Typography>
            <Typography>
              These costs potentially comprise damaging the environment, denuding the land, compromising the welfare of the
              animals themselves and reducing the quality of the meat being provided to the consumer.
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography>
              We, like many other farmers, have set out to farm animals in a more natural way that is, in many ways, the
              antithesis of modern mass production farming. Using a combination of methods, both old and new, we believe that
              taste friendly meat can be reared with additional benefits to the environment, the soil and the animals themselves.
              We want to bring these benefits straight from our farm to your kitchen. We want you to taste the difference.
            </Typography>
            <Typography>
              The essential elements are ensuring the cattle are 100% natural pasture fed and mob grazed. That the land is
              organically maintained: so no pesticides, insecticides and a huge reduction in wormers and penicillin for the
              animals. The grasses are allowed to grow to maturity which is good for the animals and super important for
              sequestering carbon into the earth. And all of this means that the quality of the soil is regenerated.
            </Typography>
          </Stack>
        </SectionItem>
      </Section>

      <Section direction="row">
        <SectionItem border alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={grassSvg} alt="grass" />
          <Typography variant="h3" align="center">
            100% Grass Fed Beef
          </Typography>
        </SectionItem>
        <SectionItem border alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={cowSvg} alt="cow" />
          <Typography variant="h3" align="center">
            Traditional Native Breeds
          </Typography>
        </SectionItem>
        <SectionItem border alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={mobGrazingSvg} alt="mob grazing" />
          <Typography variant="h3" align="center">
            Mob Grazed Daily
          </Typography>
        </SectionItem>
        <SectionItem alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={soilAssociationLogo} alt="organic" />
          <Typography variant="h3" align="center">
            Organic
          </Typography>
        </SectionItem>
      </Section>

      <Section className="find-out-more">
        <Link to="/about">
          <FindOutMoreContent>
            <Typography variant="h3" margin="45px auto">
              Find out more
            </Typography>
          </FindOutMoreContent>
        </Link>
      </Section>
    </div>
  );
};

export default Home;
